import Vue from 'vue';
import Vuex from 'vuex';
import VueI18n from 'vue-i18n'
import App from './App.vue';
import router from './router';
import AsyncComputedPlugin from "vue-async-computed";

import store from './store';
import i18n from './i18n';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


Vue.use(VueI18n)
Vue.use(Vuex);
Vue.use(AsyncComputedPlugin);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


Vue.config.productionTip = false;

new Vue({
  router,
  el: '#app',
  render: h => h(App),
  store: store,
  i18n: i18n,
});


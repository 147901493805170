import { I18nDef, i18nDefs } from '../i18ndef';

const enUS: I18nDef = {
  [i18nDefs.I_LANG]: "English (United States)",

  [i18nDefs.T_CONNECT_MY_ACCOUNT]: "Connect my account",
  [i18nDefs.T_CITANG]: "Ci Tang",
  [i18nDefs.T_MINGBI_GONGDE]: "MingBi and GongDe",
  [i18nDefs.T_MEMORIAL]: "Memorial",
  [i18nDefs.T_TRADE]: "Trade",
  [i18nDefs.T_FAQ]: "FAQ",
  [i18nDefs.T_SELECTLANG]: "Language",

  [i18nDefs.T_BMC_HEADER]: "No. {id}: {name}",
  [i18nDefs.T_CTC_BURNT_DESCRIPTION_ALIVE]: "{amount} GongDe (offertory) had been raised",
  [i18nDefs.T_CTC_BURNT_DESCRIPTION_EXPIRED]: "{amount} MingBi (joss paper) had been burnt",
  [i18nDefs.T_CTC_BURN_MINGBI_BTN_ALIVE]: "Donate Gong De",
  [i18nDefs.T_CTC_BURN_MINGBI_BTN_EXPIRED]: "Burn Ming Bi",
  [i18nDefs.T_CTC_BURN_ITEM_BTN_ALIVE]: "Burn item",
  [i18nDefs.T_CTC_BURN_ITEM_BTN_EXPIRED]: "Burn item",
  [i18nDefs.T_CTC_EDIT_INFO_BTN]: "Edit info",

  [i18nDefs.T_BMC_INTRO_ALIVE]: 'Donate some offertory to commemorate <span class="citang-burn-for-name">Honorable {name}</span>.',
  [i18nDefs.T_BMC_INTRO_EXPIRED]: 'Burn some joss paper to commemorate <span class="citang-burn-for-name">Honorable {name}</span>.',
  [i18nDefs.T_BMC_REMAINING_ALIVE]: "You currently have {amount} GongDe letter (Offertory).",
  [i18nDefs.T_BMC_REMAINING_EXPIRED]: "You currently have {amount} MingBi (Joss paper).",
  [i18nDefs.T_BMC_BURN_BTN_ALIVE]: "Donate!",
  [i18nDefs.T_BMC_BURN_BTN_EXPIRED]: "Burn!",

  [i18nDefs.T_C_GOBACK]: "Go Back",

  [i18nDefs.T_MBV_TOKEN_NAME]: "Name",
  [i18nDefs.T_MBV_TOKEN_SYMBOL]: "Symbol",
  [i18nDefs.T_MBV_TOKEN_EXCHANGE_RATE]: "1 Token = ? MingBi",
  [i18nDefs.T_MBV_TOKEN_REQUIRED_AMOUNT]: "Token amount required",
  [i18nDefs.T_MBV_TOKEN_MINGBI_TO_BUY]: "MingBi to buy",
  [i18nDefs.T_MBV_TOKEN_ACTION]: "Action",
  [i18nDefs.T_MBV_TOKEN_APPROVE]: "Approve",
  [i18nDefs.T_MBV_TOKEN_BUY]: "Buy",
  [i18nDefs.T_MBV_TOKEN_LOADING]: "Loading ...",
  [i18nDefs.T_MBV_MINGBI_AVAIABLE_INTRO]: "You currently have {amount} MingBi",
  [i18nDefs.T_MBV_MINGBI_DESCRIPTION]:
    `This is the introduction of MingBi. <br/>
  MingBi is interchangable with GongDe. <br/>
  MingBi can be bought with many tokens`,

  
  [i18nDefs.T_N_BURNING_MINGBI_STARTED_ALIVE]: "Donating GongDe for {name}...",
  [i18nDefs.T_N_BURNING_MINGBI_STARTED_EXPIRED]: "Burning MingBi for {name}...",
  [i18nDefs.T_N_BURNING_MINGBI_COMPLETED_ALIVE]: "GongDe donated for {name}!",
  [i18nDefs.T_N_BURNING_MINGBI_COMPLETED_EXPIRED]: "MingBi burnt for {name}!",
 
  [i18nDefs.T_N_BUYING_MINGBI_APPROVE_STARTED]: "Approving the spending limit for {symbol}...",
  [i18nDefs.T_N_BUYING_MINGBI_APPROVE_COMPLETED]: "Spending limit for {symbol} approved!",
  [i18nDefs.T_N_BUYING_MINGBI_BUYING_STARTED]: "Buying {amount} MingBi with {symbol}...",
  [i18nDefs.T_N_BUYING_MINGBI_BUYING_COMPLETED]: "Bought {amount} MingBi with {symbol}!",
}

export default enUS;
import { I18nDef, i18nDefs } from '../i18ndef';

const zhCN: I18nDef = {
  [i18nDefs.I_LANG]: "中文（中国）",

  [i18nDefs.T_CONNECT_MY_ACCOUNT]: "连接我的账户",
  [i18nDefs.T_CITANG]: "祠堂",
  [i18nDefs.T_MEMORIAL]: "纪念堂",
  [i18nDefs.T_MINGBI_GONGDE]: "冥币与功德券",
  [i18nDefs.T_TRADE]: "交易",
  [i18nDefs.T_FAQ]: "FAQ",
  [i18nDefs.T_SELECTLANG]: "语言",

  [i18nDefs.T_BMC_HEADER]: "祠第 {id} 号: {name}",
  [i18nDefs.T_CTC_BURNT_DESCRIPTION_ALIVE]: "已积 {amount} 功德",
  [i18nDefs.T_CTC_BURNT_DESCRIPTION_EXPIRED]: "已烧 {amount} 冥币",
  [i18nDefs.T_CTC_BURN_MINGBI_BTN_ALIVE]: "积功德",
  [i18nDefs.T_CTC_BURN_MINGBI_BTN_EXPIRED]: "烧冥币",
  [i18nDefs.T_CTC_BURN_ITEM_BTN_ALIVE]: "烧文件",
  [i18nDefs.T_CTC_BURN_ITEM_BTN_EXPIRED]: "烧文件",
  [i18nDefs.T_CTC_EDIT_INFO_BTN]: "修改祠堂信息",

  [i18nDefs.T_BMC_INTRO_ALIVE]: '捐点功德来纪念 <span class="citang-burn-for-name">{name}</span>.',
  [i18nDefs.T_BMC_INTRO_EXPIRED]: '烧点冥币来纪念<span class="citang-burn-for-name">{name}</span>.',
  [i18nDefs.T_BMC_REMAINING_ALIVE]: "您现在有 {amount} 张功德券。",
  [i18nDefs.T_BMC_REMAINING_EXPIRED]: "您现在有 {amount} 冥币。",
  [i18nDefs.T_BMC_BURN_BTN_ALIVE]: "捐！",
  [i18nDefs.T_BMC_BURN_BTN_EXPIRED]: "烧!",

  [i18nDefs.T_C_GOBACK]: "返回",

  [i18nDefs.T_MBV_TOKEN_NAME]: "名称",
  [i18nDefs.T_MBV_TOKEN_SYMBOL]: "符号",
  [i18nDefs.T_MBV_TOKEN_EXCHANGE_RATE]: "1 代币 = ？冥币",
  [i18nDefs.T_MBV_TOKEN_REQUIRED_AMOUNT]: "需要代币数量",
  [i18nDefs.T_MBV_TOKEN_MINGBI_TO_BUY]: "冥币购买数量",
  [i18nDefs.T_MBV_TOKEN_ACTION]: "操作",
  [i18nDefs.T_MBV_TOKEN_APPROVE]: "批准",
  [i18nDefs.T_MBV_TOKEN_BUY]: "购买",
  [i18nDefs.T_MBV_TOKEN_LOADING]: "加载中……",
  [i18nDefs.T_MBV_MINGBI_AVAIABLE_INTRO]: "现在有 {amount} 张冥币",
  [i18nDefs.T_MBV_MINGBI_DESCRIPTION]:
    `冥币的介绍. <br/>
  冥币和功德券可以互换 <br/>
  冥币可以用多种代币购买`,


  [i18nDefs.T_N_BURNING_MINGBI_STARTED_ALIVE]: "正在为 {name} 捐功德……",
  [i18nDefs.T_N_BURNING_MINGBI_STARTED_EXPIRED]: "正在为 {name} 烧冥币……",
  [i18nDefs.T_N_BURNING_MINGBI_COMPLETED_ALIVE]: "给 {name} 的功德捐好了!",
  [i18nDefs.T_N_BURNING_MINGBI_COMPLETED_EXPIRED]: "给 {name} 的冥币烧好了!",

  [i18nDefs.T_N_BUYING_MINGBI_APPROVE_STARTED]: "正在批准 {symbol} 的支出限制……",
  [i18nDefs.T_N_BUYING_MINGBI_APPROVE_COMPLETED]: "成功批准 {symbol} 的支出限制！",
  [i18nDefs.T_N_BUYING_MINGBI_BUYING_STARTED]: "正在用 {symbol} 购买 {amount} 冥币…",
  [i18nDefs.T_N_BUYING_MINGBI_BUYING_COMPLETED]: "成功用 {symbol} 购买了 {amount} 冥币！",
}

export default zhCN;
import detectEthereumProvider from "@metamask/detect-provider"

import { BigNumber, BigNumberish, constants, ethers, providers, Signer } from "ethers";

import contracts from "@/config/contracts.json"

// eslint-disable-next-line
import { CiTang, GongDe, CiTang__factory, GongDe__factory, ERC20, ERC20__factory } from "../../../contract/typechain"

export interface Web3Context {
  provider: providers.BaseProvider | null;
  signer: Signer | null;
  // eslint-disable-next-line
  ethereum: any;
}

export async function isConnectedToWeb3(): Promise<boolean> {
  // eslint-disable-next-line
  const ethprovider: any = await detectEthereumProvider();

  if (!ethprovider.isConnected()) {
    console.log("provider is not connected.");
    return false;
  }

  try {
    const accounts: [] = await ethprovider.request({ method: 'eth_accounts' })
    if (accounts?.length) {
      console.log("metamask has accounts");
      return true;
    } else {
      console.log("metamask has no account");
      return false;
    }
  } catch (error) {
    console.log("failed to get metamask accounts");
    console.error(error);
    return false;
  }
}

export async function connectWeb3Context(enableAccount: boolean): Promise<Web3Context> {
  const context: Web3Context = { provider: null, signer: null, ethereum: null };
  // eslint-disable-next-line
  const ethprovider: any = await detectEthereumProvider();

  if (ethprovider) {
    context.ethereum = ethprovider;
    const provider = new ethers.providers.Web3Provider(ethprovider);

    if (provider) {
      context.provider = provider;
      console.log(`provider network ${(await provider.getNetwork()).name}`);
    }

    let signer: Signer = provider.getSigner();

    if (enableAccount) {
      try {
        await ethprovider.request({ method: 'eth_requestAccounts' });
        signer = provider.getSigner();

        console.log("web3 wallet accounts requested");
      } catch (err) {
        console.error(err);
      }
    }

    if (signer) {
      try {
        const addr = await signer.getAddress();
        if (constants.AddressZero != addr) {
          context.signer = signer;
          console.log(`signer ${addr}`);
        } else {
          console.log(`signer 0x0`);
        }
      } catch (error) {
        context.signer = null;
        console.log(`signer invalid`);
      }
    }
  } else {
    console.log('Please install MetaMask!');
  }

  return context;
}

function getSupportedTokens(network: string): string[] {
  return contracts[network].GongDeExchangable;
}

function getGongDeAddress(network: string): string {
  return contracts[network].GongDe;
}

function getCiTangAddress(network: string): string {
  return contracts[network].CiTang;
}

export class Contracts {
  private static readonly MingBiUnit = BigNumber.from(10).pow(18);

  private _mingbiContract: GongDe;
  private _citangContract: CiTang;
  private _mingbiExchangeTokenContracts: ERC20[];

  public get MingBiContract(): GongDe { return this._mingbiContract }
  public get CiTangContract(): CiTang { return this._citangContract }
  public get MingBiExchangeTokenContracts(): ERC20[] { return this._mingbiExchangeTokenContracts; }

  constructor(account: providers.BaseProvider | Signer) {
    const network = "ropsten";
    // eslint-disable-next-line
    this._mingbiContract = GongDe__factory.connect(getGongDeAddress(network), account);
    // eslint-disable-next-line
    this._citangContract = CiTang__factory.connect(getCiTangAddress(network), account);
    // eslint-disable-next-line
    const contracts = getSupportedTokens(network).map(address => ERC20__factory.connect(address, account));
    this._mingbiExchangeTokenContracts = contracts;
  }

  /**
   * mingbiAmountToString
   */
  public static mingbiUnitsToDisplayString(amount: BigNumber): string {
    return amount.div(Contracts.MingBiUnit).toString();
  }

  public static mingbiUnitsToDisplay(amount: BigNumber): BigNumber {
    return amount.div(Contracts.MingBiUnit);
  }

  public static mingbiDisplayToUnits(amount: BigNumberish): BigNumber {
    return BigNumber.from(amount).mul(Contracts.MingBiUnit);
  }
}

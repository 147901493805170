















































import Component from "vue-class-component";

import { BigNumber, constants, utils } from "ethers";
import { Emit, Mixins } from "vue-property-decorator";
import { i18nDefs } from "@/i18n";
import AccountHelper from "@/mixins/accounthelper";
import MingBiWithAvailableInputBox from "@/components/MingBiWithAvailableInputBox.vue";
import { EventBus } from "@/utils/EventBus";
import { ChainConfigurations } from "@/config/configs";
import AsyncComputed from "vue-async-computed-decorator";

@Component({ components: { MingBiWithAvailableInputBox } })
export default class ReserveMemorialCard extends Mixins(AccountHelper) {
  i = i18nDefs;

  initialGongDe = constants.Zero;

  @AsyncComputed()
  async reservationGongDePrice(): Promise<BigNumber> {
    const price =
      (await this.contracts?.CiTangContract.getReservationPrice()) ??
      constants.Zero;
    return price;
  }

  @AsyncComputed()
  async reservationGongDePriceInString(): Promise<string> {
    return this.m2s(this.reservationGongDePrice as never);
  }

  @AsyncComputed()
  async reservationGongDePriceInNumber(): Promise<number> {
    return this.m2n(this.reservationGongDePrice as never);
  }

  reserving = false;
  async createCiTang(): Promise<void> {
    this.reserving = true;

    try {
      const citang = this.contracts?.CiTangContract;
      const signer = this.signer;
      if (!citang || !signer) {
        throw new Error("Contracts or signer is not ready");
      }

      console.log(
        `reserving a citang with ${this.initialGongDe.toString()} units of GongDe`
      );
      const tx = await citang.reserve(this.initialGongDe);
      const rx = await tx.wait(ChainConfigurations.CONFIRM_BLOCKS);

      const reserveEvent = citang.interface.events["Reserve(address,uint256)"];
      const id = rx.events?.find((ev) => ev.event === reserveEvent.name)
        ?.args?.[1] as BigNumber | undefined;

      if (!id) {
        throw new Error("Establish event has no arg 1");
      }

      this.reserveCiTangCompleted(id.toString());
      console.log(`reserved citang of id ${id}`);
    } catch (error) {
      console.error(error);
    } finally {
      this.reserving = false;
    }
  }

  @Emit()
  reserveCiTangCompleted(citangId: string): void {
    const message = `i18n reserved a citang. Id is ${citangId}!`;
    EventBus.sendNotificationAutoHide(message);

    if (this.$route.matched.some(({ name }) => name === "ReserveMemorial")) {
      this.$router.push({ name: "Memorial", params: { id: citangId } });
    }
  }

  async reserveCiTangRequested(event: Event): Promise<void> {
    event.preventDefault();

    await this.createCiTang();
  }
}






























import Vue from "vue";
import Component from "vue-class-component";
import AccountBanner from "@/components/AccountBanner.vue";
import LangSelector from "@/components/LangSelector.vue";
import { i18nDefs } from "@/i18n";

@Component({ components: { AccountBanner, LangSelector } })
export default class NavHeader extends Vue {
  i = i18nDefs;
}

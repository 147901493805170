
































import Component from "vue-class-component";

import { Contracts } from "@/utils/contracts";
import { CiTangModel, defaultCiTangModel } from "@/models/CiTangModel";
import { Emit, Mixins, Prop } from "vue-property-decorator";
import AsyncComputed from "vue-async-computed-decorator";
import AccountHelper from "@/mixins/accounthelper";
import MingBiWithAvailableInputBox from "@/components/MingBiWithAvailableInputBox.vue";
import { i18nDefs } from "@/i18n";
import { EventBus } from "@/utils/EventBus";
import { ChainConfigurations } from "@/config/configs";
import { constants } from "ethers";

@Component({ components: { MingBiWithAvailableInputBox } })
export default class BurnMingbiCard extends Mixins(AccountHelper) {
  i = i18nDefs;

  @Prop(CiTangModel) readonly model!: CiTangModel;

  get citangModel(): CiTangModel {
    return this.model ?? defaultCiTangModel;
  }

  mingbiAmountToBurn = constants.Zero;
  burningMingbi = false;

  @AsyncComputed()
  async burnable(): Promise<boolean> {
    if (!this.signer || !this.contracts) {
      return false;
    }

    if (
      this.mingbiAmountToBurn.eq(0) ||
      this.mingbiAmountToBurn.gt(this.accountMingbiAvailable as never)
    ) {
      return false;
    }

    if (this.burningMingbi) {
      return false;
    }

    return true;
  }

  @Emit()
  burnMingbiCancelled(): void {
    //
  }

  async onBurnRequested(): Promise<void> {
    this.burningMingbi = true;
    console.log(
      `user confirmed burning MingBi, amount ${this.mingbiAmountToBurn.toString()}`
    );
    console.log(`Burn MingBis = ${this.mingbiAmountToBurn.toString()} units`);

    if (this.signer && this.contracts) {
      const citang = this.contracts.CiTangContract;
      try {
        const tx = await citang.burnGongdeFor(
          this.citangModel.tokenId,
          this.mingbiAmountToBurn
        );

        const message = this.$t(
          this.citangModel.alive
            ? this.i.T_N_BURNING_MINGBI_STARTED_ALIVE
            : this.i.T_N_BURNING_MINGBI_STARTED_EXPIRED,
          { name: this.citangModel.forName }
        ).toString();

        EventBus.sendNotificationAutoHide(message);
        await tx.wait(ChainConfigurations.CONFIRM_BLOCKS);
        this.burnMingbiCompleted();
      } catch (error) {
        console.error(error);
      }
    } else {
      console.log(
        `onBurnRequested<m>: no signer available or invalid contracts`
      );
    }
    this.burningMingbi = false;
  }

  @Emit()
  burnMingbiCompleted(): void {
    console.log("emit burn mingbi completed");

    const message = this.$t(
      this.citangModel.alive
        ? this.i.T_N_BURNING_MINGBI_COMPLETED_ALIVE
        : this.i.T_N_BURNING_MINGBI_COMPLETED_EXPIRED,
      { name: this.citangModel.forName }
    ).toString();
    EventBus.sendNotificationAutoHide(message);
  }
}
















import Component from "vue-class-component";

import { BigNumber, constants, utils } from "ethers";
import { Emit, Mixins, Prop, Watch } from "vue-property-decorator";
import AccountHelper from "@/mixins/accounthelper";
import { v4 as uuidv4 } from "uuid";

@Component
export default class MingBiWithAvailableInputBox extends Mixins(AccountHelper) {
  @Prop({ default: `MBWAIB_${uuidv4()}`, type: String }) inputId!: string;
  @Prop({ default: false, type: Boolean }) required!: boolean;
  @Prop({ default: constants.Zero, type: BigNumber }) value!: BigNumber;
  @Prop({ default: 0, type: Number }) min!: number;

  gongde = "0";

  @Watch("min")
  onMinChanged() {
    if (this.gongde === "0") {
      this.gongde = this.min.toString();
    }
  }

  get amountValid(): boolean {
    const gongde = Number.parseInt(this.gongde);
    return (
      gongde <= (this.accountMingbiAvailableNumber as never) &&
      gongde >= this.min
    );
  }

  @Watch("gongde")
  onGongdeChanged() {
    try {
      const gongde = BigNumber.from(this.gongde);
      this.$emit("input", this.n2m(gongde));
    } catch (error) {
      this.$emit("input", constants.Zero);
    }
  }
}

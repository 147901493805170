import Vue from "vue";

import { Contracts } from "@/utils/contracts";
import { BigNumber, BigNumberish, constants, Signer } from "ethers";
import store from "@/store";
import { getWalletMethods, WalletGetters } from "@/store";
import Component from "vue-class-component";
import AsyncComputed from "vue-async-computed-decorator";

@Component
export default class AccountHelper extends Vue {
  get signer(): Signer | null {
    return store.getters[getWalletMethods(WalletGetters.getSigner)];
  }

  get signerReady(): boolean {
    return store.getters[getWalletMethods(WalletGetters.getSigner)];
  }

  get contracts(): Contracts | null {
    return store.getters[getWalletMethods(WalletGetters.getContracts)];
  }

  get network(): string | null {
    return store.getters[getWalletMethods(WalletGetters.getNetwork)];
  }

  @AsyncComputed()
  async accountMingbiAvailable(): Promise<BigNumber> {
    const addr = await this.signer?.getAddress();

    if (!addr) {
      return constants.Zero;
    }

    if (this.contracts === null) {
      throw new Error(
        "store.wallet.contract is null! this is not supposed to happen as store.wallet.signer is valid"
      );
    }

    const balance = await this.contracts.MingBiContract.balanceOf(addr); // always not null once signer is ready
    return balance;
  }

  @AsyncComputed()
  async accountMingbiAvailableString(): Promise<string> {
    return this.m2s(this.accountMingbiAvailable as never);
  }

  @AsyncComputed()
  async accountMingbiAvailableNumber(): Promise<number> {
    return this.m2n(this.accountMingbiAvailable as never);
  }

  m2s(mingbiAmount: BigNumber | null): string {
    return mingbiAmount
      ? Contracts.mingbiUnitsToDisplayString(mingbiAmount)
      : "";
  }

  m2n(mingbiAmount: BigNumber | null): number {
    return mingbiAmount
      ? Contracts.mingbiUnitsToDisplay(mingbiAmount).toNumber()
      : 0;
  }

  n2m(mingbiDisplayAmount: BigNumberish): BigNumber {
    return Contracts.mingbiDisplayToUnits(mingbiDisplayAmount);
  }
}

import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';

import CreateMemorialView from '@/pages/CreateMemorialView.vue';
import MemorialView from '@/pages/MemorialView.vue';
import MingBiView from '@/pages/MingBiView.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/memorials',
    name: 'Memorials',
    component: MemorialView
  },
  {
    path: '/memorial/:id',
    name: 'Memorial',
    component: MemorialView
  },
  {
    path: '/memorial-op/establish',
    name: 'EstablishMemorial',
    component: CreateMemorialView
  },
  {
    path: '/memorial-op/reserve',
    name: 'ReserveMemorial',
    component: CreateMemorialView
  },
  {
    path: '/mingbi',
    name: 'Mingbi',
    component: MingBiView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

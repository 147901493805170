









import Vue from "vue";
import Component from "vue-class-component";

import { EventBus } from "@/utils/EventBus";

import NavHeader from "@/components/NavHeader.vue";

@Component({ components: { NavHeader } })
export default class App extends Vue {
  mounted(): void {
    EventBus.onNotificationAutoHide((payload) => {
      this.makeToast(
        payload.message,
        payload.variant ?? "info",
        false,
        payload.title
      );
    });

    EventBus.onNotificationNoAutoHide((payload) => {
      this.makeToast(
        payload.message,
        payload.variant ?? "info",
        true,
        payload.title
      );
    });
  }

  makeToast(
    message: string,
    variant: string,
    perm: boolean,
    title?: string
  ): void {
    const h = this.$createElement;
    const vNodesMsgs = h("p", { class: ["text-center", "mb-0"] }, [message]);
    const vNodesTitles =
      title === undefined
        ? undefined
        : [
            h(
              "div",
              {
                class: [
                  "d-flex",
                  "flex-grow-1",
                  "align-items-baseline",
                  "mr-2",
                ],
              },
              [title]
            ),
          ];
    this.$bvToast.toast(vNodesMsgs, {
      title: vNodesTitles,
      solid: true,
      variant: variant,
      noAutoHide: perm,
      noCloseButton: !perm,
    });
  }
}

import { LocaleMessage, LocaleMessageObject } from "vue-i18n";

export enum i18nDefs {
    I_LANG = "info.lang",

    T_CONNECT_MY_ACCOUNT = "text.connectMyAccount",
    T_CITANG = "text.citang",
    T_MINGBI_GONGDE = "text.gongdeletter",
    T_MEMORIAL = "text.memorial",
    T_TRADE = "text.trade",
    T_FAQ = "text.faq",
    T_SELECTLANG = "text.selectlang",

    // Burn mingbi card
    T_BMC_HEADER = "text.bmc.hdr",
    T_BMC_INTRO_ALIVE = "text.bmc.intro.a",
    T_BMC_INTRO_EXPIRED = "text.bmc.intro.e",
    T_BMC_REMAINING_ALIVE = "text.bmc.remaining.a",
    T_BMC_REMAINING_EXPIRED = "text.bmc.remaining.e",
    T_BMC_BURN_BTN_ALIVE = "text.bmc.burnbtn.a",
    T_BMC_BURN_BTN_EXPIRED = "text.bmc.burnbtn.e",

    // CiTang card
    T_CTC_BURNT_DESCRIPTION_ALIVE = "text.ctc.burntdesc.a",
    T_CTC_BURNT_DESCRIPTION_EXPIRED = "text.ctc.burntdesc.e",
    T_CTC_BURN_MINGBI_BTN_ALIVE = "text.ctc.burnmingbibtn.a",
    T_CTC_BURN_MINGBI_BTN_EXPIRED = "text.ctc.burnmingbibtn.e",
    T_CTC_BURN_ITEM_BTN_ALIVE = "text.ctc.burnitembtn.a",
    T_CTC_BURN_ITEM_BTN_EXPIRED = "text.ctc.burnitembtn.e",
    T_CTC_EDIT_INFO_BTN = "text.ctc.editinfobtn",

    // MingBi View
    T_MBV_TOKEN_NAME = "text.mbc.token.name",
    T_MBV_TOKEN_SYMBOL = "text.mbc.token.symbol",
    T_MBV_TOKEN_EXCHANGE_RATE = "text.mbc.token.exchangerate",
    T_MBV_TOKEN_REQUIRED_AMOUNT = "text.mbc.token.requiredamount",
    T_MBV_TOKEN_MINGBI_TO_BUY = "text.mbc.token.mingbi.tobuy",
    T_MBV_TOKEN_ACTION = "text.mbc.token.action",
    T_MBV_TOKEN_APPROVE = "text.mbc.token.approve",
    T_MBV_TOKEN_BUY = "text.mbc.token.buy",
    T_MBV_TOKEN_LOADING = "text.mbc.token.loading",

    T_MBV_MINGBI_AVAIABLE_INTRO = "text.mbc.mingbi.available.intro",
    T_MBV_MINGBI_DESCRIPTION = "text.mbc.mingbi.desc",

    // Common
    T_C_GOBACK = "text.common.goback",

    // Notifications
    T_N_BURNING_MINGBI_STARTED_ALIVE = "text.n.burnmb.started.a",
    T_N_BURNING_MINGBI_STARTED_EXPIRED = "text.n.burnmb.started.e",
    T_N_BURNING_MINGBI_COMPLETED_ALIVE = "text.n.burnmb.completed.a",
    T_N_BURNING_MINGBI_COMPLETED_EXPIRED = "text.n.burnmb.completed.e",

    T_N_BUYING_MINGBI_APPROVE_STARTED = "text.n.buymb.approve.started",
    T_N_BUYING_MINGBI_APPROVE_COMPLETED = "text.n.buymb.approve.completed",
    T_N_BUYING_MINGBI_BUYING_STARTED = "text.n.burnmb.buy.started",
    T_N_BUYING_MINGBI_BUYING_COMPLETED = "text.n.burnmb.buy.completed",
}

export interface I18nDef extends LocaleMessageObject {
    [i18nDefs.I_LANG]: LocaleMessage;

    [i18nDefs.T_CONNECT_MY_ACCOUNT]: LocaleMessage;
    [i18nDefs.T_CITANG]: LocaleMessage;
    [i18nDefs.T_MINGBI_GONGDE]: LocaleMessage;
    [i18nDefs.T_MEMORIAL]: LocaleMessage;
    [i18nDefs.T_TRADE]: LocaleMessage;
    [i18nDefs.T_FAQ]: LocaleMessage;

    [i18nDefs.T_BMC_HEADER]: LocaleMessage;

    [i18nDefs.T_CTC_BURNT_DESCRIPTION_ALIVE]: LocaleMessage;
    [i18nDefs.T_CTC_BURNT_DESCRIPTION_EXPIRED]: LocaleMessage;
    [i18nDefs.T_CTC_BURN_MINGBI_BTN_ALIVE]: LocaleMessage;
    [i18nDefs.T_CTC_BURN_MINGBI_BTN_EXPIRED]: LocaleMessage;
    [i18nDefs.T_CTC_BURN_ITEM_BTN_ALIVE]: LocaleMessage;
    [i18nDefs.T_CTC_BURN_ITEM_BTN_EXPIRED]: LocaleMessage;
    [i18nDefs.T_CTC_EDIT_INFO_BTN]: LocaleMessage;

    [i18nDefs.T_BMC_INTRO_ALIVE]: LocaleMessage;
    [i18nDefs.T_BMC_INTRO_EXPIRED]: LocaleMessage;
    [i18nDefs.T_BMC_REMAINING_ALIVE]: LocaleMessage;
    [i18nDefs.T_BMC_REMAINING_EXPIRED]: LocaleMessage;
    [i18nDefs.T_BMC_BURN_BTN_ALIVE]: LocaleMessage;
    [i18nDefs.T_BMC_BURN_BTN_EXPIRED]: LocaleMessage;

    [i18nDefs.T_C_GOBACK]: LocaleMessage;

    [i18nDefs.T_MBV_TOKEN_NAME]: LocaleMessage;
    [i18nDefs.T_MBV_TOKEN_SYMBOL]: LocaleMessage;
    [i18nDefs.T_MBV_TOKEN_EXCHANGE_RATE]: LocaleMessage;
    [i18nDefs.T_MBV_TOKEN_REQUIRED_AMOUNT]: LocaleMessage;
    [i18nDefs.T_MBV_TOKEN_MINGBI_TO_BUY]: LocaleMessage;
    [i18nDefs.T_MBV_TOKEN_ACTION]: LocaleMessage;
    [i18nDefs.T_MBV_TOKEN_APPROVE]: LocaleMessage;
    [i18nDefs.T_MBV_TOKEN_BUY]: LocaleMessage;
    [i18nDefs.T_MBV_TOKEN_LOADING]: LocaleMessage;
    [i18nDefs.T_MBV_MINGBI_AVAIABLE_INTRO]: LocaleMessage;
    [i18nDefs.T_MBV_MINGBI_DESCRIPTION]: LocaleMessage;

    [i18nDefs.T_N_BURNING_MINGBI_STARTED_ALIVE]: LocaleMessage;
    [i18nDefs.T_N_BURNING_MINGBI_STARTED_EXPIRED]: LocaleMessage;
    [i18nDefs.T_N_BURNING_MINGBI_COMPLETED_ALIVE]: LocaleMessage;
    [i18nDefs.T_N_BURNING_MINGBI_COMPLETED_EXPIRED]: LocaleMessage;
    [i18nDefs.T_N_BUYING_MINGBI_APPROVE_STARTED]: LocaleMessage;
    [i18nDefs.T_N_BUYING_MINGBI_APPROVE_COMPLETED]: LocaleMessage;
    [i18nDefs.T_N_BUYING_MINGBI_BUYING_STARTED]: LocaleMessage;
    [i18nDefs.T_N_BUYING_MINGBI_BUYING_COMPLETED]: LocaleMessage;
}
















import Component from "vue-class-component";

import { Mixins } from "vue-property-decorator";
import { i18nDefs } from "@/i18n";
import AccountHelper from "@/mixins/accounthelper";
import EstablishMemorialCard from "@/components/EstablishMemorialCard.vue";
import ReserveMemorialCard from "@/components/ReserveMemorialCard.vue";

enum CreateType {
  Unknown,
  Establish,
  Reserve,
}

@Component({ components: { EstablishMemorialCard, ReserveMemorialCard } })
export default class CreateMemorialView extends Mixins(AccountHelper) {
  i = i18nDefs;
  CreateType = CreateType;
  createType = CreateType.Unknown;

  mounted(): void {
    if (this.$route.name === "EstablishMemorial") {
      this.createType = CreateType.Establish;
    } else if (this.$route.name === "ReserveMemorial") {
      this.createType = CreateType.Reserve;
    } else {
      console.error(`unknown route name ${this.$route.name}`);
      this.createType = CreateType.Unknown;
    }
  }
}







import Vue from "vue";
import Component from "vue-class-component";

import CiTangView from "@/components/CiTangView.vue";

@Component({ components: { CiTangView } })
export default class MemorialView extends Vue {
  memorialId = "";

  async mounted(): Promise<void> {
    // await connectWeb3();

    this.memorialId = this.$route.params.id;
    console.log(`Memorial has the id of ${this.memorialId}`);
  }
}

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { i18nDefs } from './i18ndef'

Vue.use(VueI18n);

import zhCN from './config/zh-CN';
import enUS from './config/en-US';

const i18n = new VueI18n({
    locale: localStorage.getItem('locale') || 'en-US',
    messages: {
        "zh-CN": zhCN,
        "en-US": enUS,
    },
})

export default i18n;
export { i18nDefs };
import { connectWeb3Context, Contracts, Web3Context } from "@/utils/contracts";
import { providers, Signer } from "ethers";
import { Module } from "vuex";
import { RootState } from ".";

export enum WalletGetters {
    getProviderReady = "getProviderReady",
    getSignerReady = "getSignerReady",
    getContracts = "getContracts",
    getContext = "getContext",
    getProvider = "getProvider",
    getSigner = "getSigner",
    getNetwork = "getNetwork",
}

export enum WalletActions {
    initializeWallet = "initializeWallet",
    connectWallet = "connectWallet"
}

export function getWalletMethods(method: WalletMutations | WalletGetters | WalletActions): string {
    return `wallet/${method}`;
}

interface Context {
    provider: providers.BaseProvider | null;
    signer: Signer | null;
    contracts: Contracts | null;
}

export interface WalletState {
    walletReady: boolean;
    context: Context;
}

export enum WalletMutations {
    setWallet = "setWallet",
}

export const wallet: Module<WalletState, RootState> = {
    namespaced: true,
    state: {
        walletReady: false,
        context: { provider: null, signer: null, contracts: null }
    },
    mutations: {
        [WalletMutations.setWallet](state, context: Web3Context) {
            const account = context.signer ?? context.provider ?? undefined;

            if (account) {
                state.context.contracts = new Contracts(account);
                state.context.provider = context.provider;
                state.context.signer = context.signer;
                state.walletReady = true;
                console.log(`setWallet<mut>: provider ${!!context.provider}, signer ${!!context.signer}`);
            }
            else {
                state.walletReady = false;
                console.log("setWallet<mut>: no account");
            }
        }
    },
    actions: {
        async [WalletActions.initializeWallet]({ commit }) {
            const context = await connectWeb3Context(false);
            commit(WalletMutations.setWallet, context);
        },

        async [WalletActions.connectWallet]({ commit }) {
            const context = await connectWeb3Context(true);
            commit(WalletMutations.setWallet, context);
        }
    },
    getters: {
        [WalletGetters.getNetwork]: (state): string | null => {
            const provider = state.context.provider;
            if (provider) {
                return provider.network.name;
            } else {
                return null;
            }
        },

        [WalletGetters.getProvider]: (state): providers.BaseProvider | null => {
            return state.context.provider;
        },

        [WalletGetters.getProviderReady]: (state): boolean => {
            return state.context.provider != null;
        },

        [WalletGetters.getSignerReady]: (state): boolean => {
            return state.context.signer != null;
        },

        [WalletGetters.getContracts]: (state): Contracts | null => {
            return state.context.contracts;
        },

        [WalletGetters.getContext]: (state): Context => {
            return state.context;
        },

        [WalletGetters.getSigner]: (state): Signer | null => {
            return state.context.signer;
        },
    }
}
import Vue from "vue";
import Vuex from "vuex";
// import { RootState } from "./store";
import { wallet } from "./wallet"
// import { citang } from "./citang"

Vue.use(Vuex);


interface RootState {
  wallet: WalletState;
  // citang: CiTangState;
}

export default new Vuex.Store<RootState>({
  modules: {
    wallet,
    // citang,
  },
});

import { WalletState, getWalletMethods, WalletActions, WalletGetters, WalletMutations } from "./wallet";
// import { CiTangState, getCiTangMethods, CiTangActions, CiTangGetters, CiTangMutations } from "./citang"

export { RootState };
export { getWalletMethods, WalletActions, WalletGetters, WalletMutations };
// export { getCiTangMethods, CiTangActions, CiTangGetters, CiTangMutations };
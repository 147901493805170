

























































import Vue from "vue";
import Component from "vue-class-component";

import { Signer } from "ethers";
import { CiTangModel, defaultCiTangModel } from "@/models/CiTangModel";
import { Emit, Prop } from "vue-property-decorator";
import { i18nDefs } from "@/i18n";
import store from "@/store";
import { getWalletMethods, WalletGetters } from "@/store/wallet";
import AsyncComputed from "vue-async-computed-decorator";

@Component
export default class CiTangCard extends Vue {
  i = i18nDefs;

  @Prop(String) readonly citangId!: string;
  @Prop(Boolean) readonly loadingEnabled!: boolean;
  @Prop(CiTangModel) readonly model!: CiTangModel;

  @Prop(Boolean) readonly burnMingbiEnabled!: boolean;
  @Prop(Boolean) readonly burnItemEnabled!: boolean;

  imgLoadFailed = false;
  placeholderImage = require("@/assets/image/citang-placeholder.jpg");

  get citangModel() : CiTangModel
  {
    return this.model ?? defaultCiTangModel;
  }

  get modelLoading(): boolean {
    return !this.citangModel?.ready ?? true;
  }

  get signer(): Signer | null {
    return store.getters[
      getWalletMethods(WalletGetters.getSigner)
    ] as Signer | null;
  }

  @AsyncComputed()
  async isOwner(): Promise<boolean> {
    const _ = this.signer;
    const __ = this.citangModel;

    if ((_ as never) === __) {
      // this is for reactivity. it's useless, but don't delete it
      return false;
    }

    const addr = (await this.signer?.getAddress()) ?? "n/a";
    return addr === this.citangModel.owner;
  }

  @Emit()
  burnMingbiRequested(): string {
    return this.citangModel.id;
  }

  @Emit()
  sellCiTangRequested(): string {
    return this.citangModel.id;
  }
}

































import Vue from "vue";
import Component from "vue-class-component";

import { BigNumber } from "ethers";
import { CiTangModel, loadCitangModelFromChain, defaultCiTangModel } from "@/models/CiTangModel";
import { Prop } from "vue-property-decorator";
import AsyncComputed from "vue-async-computed-decorator";
import CiTangCard from "@/components/CiTangCard.vue";
import BurnMingbiCard from "@/components/BurnMingbiCard.vue";
import store from "@/store";
import { getWalletMethods, WalletGetters } from "@/store/wallet";

enum ViewPages {
  CiTangInfo = 0,
  BurnMingBi = 1,
  BurnItem = 2,
  Trade = 3,
  Edit = 4,
}

@Component({ components: { CiTangCard, BurnMingbiCard } })
export default class CiTangView extends Vue {
  @Prop(String) readonly citangId!: string;

  private viewPages = ViewPages;
  currentPage = ViewPages.CiTangInfo;

  @AsyncComputed()
  async model(): Promise<CiTangModel> {
    console.log(`model<computed>: CiTang id ${this.citangId}`);

    // FIXME: catch invalid
    const id = BigNumber.from(this.citangId);
    const contracts =
      store.getters[getWalletMethods(WalletGetters.getContracts)];
    if (!contracts) {
      console.log("model<computed>: wallet & contracts not yet ready");
      return defaultCiTangModel;
    }

    const model = await loadCitangModelFromChain(contracts.CiTangContract, id);

    return model ?? defaultCiTangModel;
  }

  setActiveViewPage(page: ViewPages): void {
    this.currentPage = page;
  }
}




























































import AccountHelper from "@/mixins/accounthelper";
import { Emit, Mixins } from "vue-property-decorator";
import Component from "vue-class-component";
import { BigNumber, constants, ContractTransaction } from "ethers";
import AsyncComputed from "vue-async-computed-decorator";
import { ERC20 } from "../../../contract/typechain";
import { ChainConfigurations } from "@/config/configs";
import { Big } from "big.js";
import { EventBus } from "@/utils/EventBus";
import { i18nDefs } from "@/i18n";

function bn2b(num: BigNumber): Big {
  return new Big(num.toString());
}

function divToString(dividend: BigNumber, divisor: BigNumber): string {
  return bn2b(dividend).div(bn2b(divisor)).toString();
}

function divToBig(dividend: BigNumber, divisor: BigNumber): Big {
  return bn2b(dividend).div(bn2b(divisor));
}

const b10 = BigNumber.from(10);

class TokenInfo {
  private _name: string;
  private _symbol: string;
  private _ratio: BigNumber;
  private _balance: BigNumber;
  private _allowance: BigNumber;
  private _address: string;
  private _decimal: number;
  private _factor: BigNumber;
  private _isETH: boolean;

  private _contract?: ERC20; // undefined means ETH

  // model
  mingbiAmountStr = "0";
  approving = false;
  purchasing = false;

  get mingbiAmount() {
    const amount = Number.parseInt(this.mingbiAmountStr);
    if (isNaN(amount)) {
      return 0;
    } else {
      return amount;
    }
  }

  get name(): string {
    return this._name;
  }

  get symbol(): string {
    return this._symbol;
  }

  get ratio(): BigNumber {
    return this._ratio;
  }

  get ratioString(): string {
    return divToString(this._factor, this._ratio);
  }

  get balance(): BigNumber {
    return this._balance;
  }

  get balanceString(): string {
    return divToString(this._balance, this._factor);
  }

  get allowance(): BigNumber {
    return this._allowance;
  }

  get address(): string {
    return this._address;
  }

  get decimal(): number {
    return this._decimal;
  }

  get isETH(): boolean {
    return this._isETH;
  }

  get contract(): ERC20 | undefined {
    return this._contract;
  }

  get approvalRequired(): boolean {
    if (this.isETH) {
      return false;
    } else {
      return (
        this._allowance.isZero() ||
        this._allowance.lt(this.tokenAmountRequiredInUnits)
      );
    }
  }

  get tokenAmountRequiredInUnits(): BigNumber {
    const amount = this._ratio.mul(this.mingbiAmount);
    return amount;
  }

  get tokenAmountRequiredInToken(): Big {
    return divToBig(this.tokenAmountRequiredInUnits, this._factor);
  }

  get canPurchase(): boolean {
    return this.mingbiAmount > 0 && !this.approvalRequired;
  }

  get canApprove(): boolean {
    return (
      !this.tokenAmountRequiredInUnits.isZero() &&
      this._allowance.lt(this.tokenAmountRequiredInUnits)
    );
  }

  constructor(
    name: string,
    symbol: string,
    ratio: BigNumber,
    balance: BigNumber,
    decimal: number,
    allowance: BigNumber,
    isETH: boolean,
    contract?: ERC20
  ) {
    this._factor = b10.pow(decimal);
    this._name = name;
    this._symbol = symbol;
    this._ratio = ratio;
    this._balance = balance;
    this._decimal = decimal;
    this._allowance = allowance;
    this._isETH = isETH;
    if (isETH) {
      this._address = "ETH";
    } else {
      if (!contract) {
        throw new Error("contract must be supplied if not ETH");
      }
      this._contract = contract;
      this._address = contract.address;
    }
  }
}

@Component
export default class MingBiView extends Mixins(AccountHelper) {
  i = i18nDefs;

  tokenInfoFields = [
    {
      key: "symbol",
      sortable: true,
      label: this.$t(this.i.T_MBV_TOKEN_SYMBOL),
      tdClass: "align-middle",
      stickyColumn: true,
    },
    {
      key: "name",
      label: this.$t(this.i.T_MBV_TOKEN_NAME),
      tdClass: "align-middle",
    },
    {
      key: "ratio",
      sortable: true,
      label: this.$t(this.i.T_MBV_TOKEN_EXCHANGE_RATE),
      tdClass: "align-middle",
    },
    {
      key: "tokenAmountRequiredInToken",
      label: this.$t(this.i.T_MBV_TOKEN_REQUIRED_AMOUNT),
      tdClass: "align-middle",
    },
    {
      key: "mingbiAmount",
      label: this.$t(this.i.T_MBV_TOKEN_MINGBI_TO_BUY),
      tdClass: "align-middle",
    },
    {
      key: "action",
      label: this.$t(this.i.T_MBV_TOKEN_ACTION),
      tdClass: "align-middle",
    },
  ];

  @AsyncComputed()
  async tokenInfo(): Promise<TokenInfo[]> {
    if (!this.contracts || !this.signer) {
      console.log("contract or singer is not ready");
      return [];
    }

    const signer = this.signer;
    let myAddress = await signer.getAddress();

    const tokens = this.contracts.MingBiExchangeTokenContracts;
    const mingbi = this.contracts.MingBiContract;
    const results = await Promise.all(
      tokens.map(async (token) => {
        try {
          const ratio = await mingbi.getRatioForToken(token.address);

          if (ratio.isZero()) {
            console.log(`Buying with ${token.address} is no longer supported`);
            return null;
          }

          const name = await token.name();
          const symbol = await token.symbol();
          const allowance = await token.allowance(myAddress, mingbi.address);

          const decimals = await token.decimals();

          const balance = signer
            ? await token.balanceOf(myAddress)
            : constants.Zero;

          return new TokenInfo(
            name,
            symbol,
            ratio,
            balance,
            decimals,
            allowance,
            false,
            token
          );
        } catch (error) {
          console.log(`failed when retrieving info for ${token.address}`);
          console.error(error);
          return null;
        }
      })
    );

    // ETH
    {
      try {
        const ratio = await mingbi.getRatioForETH();
        if (ratio.isZero()) {
          throw new Error("Buying with ETH is no longer supported");
        }
        const decimals = 18;

        const balance = signer ? await signer.getBalance() : constants.Zero;

        results.push(
          new TokenInfo(
            "ETH",
            "ETH",
            ratio,
            balance,
            decimals,
            constants.Zero,
            true
          )
        );
      } catch (error) {
        console.log(`failed when retrieving info for ETH`);
        console.error(error);
      }
    }

    return results.filter((token) => token !== null) as TokenInfo[];
  }

  async approve(token: TokenInfo): Promise<void> {
    if (token.isETH) {
      throw new Error("This is a bug: ETH should not be approved");
    }

    if (!token.contract) {
      throw new Error("contract can't be undefined if not ETH");
    }
    const mingbiAddr = this.contracts?.MingBiContract.address;
    if (!mingbiAddr) {
      throw new Error(
        "This is a bug: mingbi addr is undefined due to null contracts"
      );
    }

    token.approving = true;
    try {
      const tx = await token.contract.approve(mingbiAddr, constants.MaxUint256);
      EventBus.sendNotificationAutoHide(
        this.$t(i18nDefs.T_N_BUYING_MINGBI_APPROVE_STARTED, {
          symbol: token.symbol,
        })
      );
      await tx.wait(ChainConfigurations.CONFIRM_BLOCKS);
      this.approveCompleted(token.symbol);
    } catch (error) {
      console.log(`failed to approve spending limit for ${token.symbol}`);
      console.error(error);
    }
    token.approving = false;
  }

  async purchase(token: TokenInfo): Promise<void> {
    if (!this.contracts || !this.signer) {
      console.log("contract or singer is not ready");
      return;
    }

    token.purchasing = true;

    try {
      let tx: ContractTransaction;
      if (token.isETH) {
        console.log(`buying with ${token.tokenAmountRequiredInUnits} wei`);
        tx = await this.contracts.MingBiContract.buyWithEther({
          value: token.tokenAmountRequiredInUnits,
        });
        EventBus.sendNotificationAutoHide(
          this.$t(i18nDefs.T_N_BUYING_MINGBI_BUYING_STARTED, {
            amount: token.mingbiAmount,
            symbol: "ETH",
          })
        );
      } else {
        console.log(
          `buying with ${token.tokenAmountRequiredInUnits} token units`
        );
        tx = await this.contracts.MingBiContract.buyWithToken(
          token.address,
          token.mingbiAmount
        );
        EventBus.sendNotificationAutoHide(
          this.$t(i18nDefs.T_N_BUYING_MINGBI_BUYING_STARTED, {
            amount: token.mingbiAmount,
            symbol: token.symbol,
          })
        );
      }

      await tx.wait(ChainConfigurations.CONFIRM_BLOCKS);
      this.purchaseCompleted(token.symbol, token.mingbiAmount);
      token.mingbiAmountStr = "0";
    } catch (error) {
      console.log(`failed to pruchase MingBi with ${token.symbol}`);
      console.error(error);
    }

    token.purchasing = false;
  }

  @Emit()
  approveCompleted(symbol: string): void {
    EventBus.sendNotificationAutoHide(
      this.$t(i18nDefs.T_N_BUYING_MINGBI_APPROVE_COMPLETED, {
        symbol: symbol,
      })
    );
  }

  @Emit()
  purchaseCompleted(symbol: string, amount: number): void {
    EventBus.sendNotificationAutoHide(
      this.$t(i18nDefs.T_N_BUYING_MINGBI_BUYING_COMPLETED, {
        amount: amount,
        symbol: symbol,
      }).toString()
    );
  }
}

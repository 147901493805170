import Vue from 'vue'
import Component from 'vue-class-component';
import { LocaleMessage } from 'vue-i18n';

export interface EventNotificationPayload {
    message: string;
    title?: string;
    variant?: string;
}

enum EventBusType {
    NotificationP = "notif.perm",
    NotificationA = "notif.autoclose",
}

@Component
export class EventBusComponent extends Vue {
    // God damn TypeScript is so weak on overloading.
    // I have to use this Windows-style Ex function.
    sendNotificationAutoHideEx(payload: EventNotificationPayload): void {
        this.$emit(EventBusType.NotificationA, payload);
    }

    sendNotificationAutoHide(message: string | LocaleMessage, variant?: string): void {
        this.$emit(EventBusType.NotificationA, {
            message: message.toString(),
            variant: variant
        } as EventNotificationPayload);
    }

    sendNotificationNoAutoHideEx(payload: EventNotificationPayload): void {
        this.$emit(EventBusType.NotificationP, payload);
    }

    sendNotificationNoAutoHide(message: string, title?: string, variant?: string): void {
        this.$emit(EventBusType.NotificationP, {
            message: message,
            title: title,
            variant: variant
        } as EventNotificationPayload);
    }

    onNotificationAutoHide(callback: (payload: EventNotificationPayload) => void): void {
        this.$on(EventBusType.NotificationA, callback);
    }

    onNotificationNoAutoHide(callback: (payload: EventNotificationPayload) => void): void {
        this.$on(EventBusType.NotificationP, callback);
    }
}

export const EventBus = new EventBusComponent();


import { BigNumber, BigNumberish, constants } from "ethers";
import { CiTangMetadata } from "./CiTangMetadata";
import { Contracts } from "../utils/contracts";
import axios from "axios";
import { CiTang } from "../../../contract/typechain";

export class CiTangModel {
  constructor(
    id: BigNumberish,
    forName: string,
    forAddress: string,
    burntAmount: BigNumberish,
    achievements: string,
    metadata: CiTangMetadata,
    owner: string,
    alive: boolean,
    initialized?: boolean
  ) {
    this._id = BigNumber.from(id);
    this._forName = forName;
    this._forAddress = forAddress;
    this._burntAmount = BigNumber.from(burntAmount);
    this._achievements = achievements;
    this._metadata = metadata;
    this._owner = owner;
    this._alive = alive;
    if (initialized === undefined) {
      this._initialized = true;
    } else {
      this._initialized = initialized;
    }
  }

  private _id: BigNumber;
  private _forName: string;
  private _forAddress: string;
  private _burntAmount: BigNumber;
  private _achievements: string;
  private _metadata: CiTangMetadata;
  private _owner: string;
  private _alive: boolean;
  private _initialized: boolean;

  get tokenId(): BigNumber { return this._id; }
  get id(): string { return this._id.toString(); }
  get forName(): string { return this._forName; }
  get forAddress(): string { return this._forAddress; }
  get burntAmount(): string { return Contracts.mingbiUnitsToDisplayString(this._burntAmount); }
  get achievements(): string { return this._achievements; }
  get image(): string { return this._metadata.image; }
  get alive(): boolean { return this._alive; }
  get ready(): boolean { return this._id.gt(constants.Zero); }
  get owner(): string { return this._owner; }
  get initialized(): boolean { return this._initialized; }
}

export const defaultCiTangModel = new CiTangModel(
  0, // default id 0
  "...",
  "...",
  0, // default no migngbi
  "...",
  { image: "", name: "", description: "" },
  constants.AddressZero, // default no address
  true, // default alive
  true // default initialized
);

export async function loadCitangModelFromChain(citang: CiTang, citangId: BigNumber): Promise<CiTangModel | null> {
  // FIXME: catch invalid id
  const id = citangId;
  console.log(`loading ci tang ${id.toString()}`);

  try {
    const owner = await citang.ownerOf(id);
    const burntAmount = await citang.getBurntGongdeFor(id);

    const initialized = await citang.isInitialized(id);
    if (!initialized) {
      // not initialzed yet
      return new CiTangModel(id, "", "", burntAmount, "", { image: "", name: "", description: "" }, owner, true, false);
    }

    const memorialFor = await citang.getHonorableName(id);
    const memorialForAddress = await citang.getHonorableAddress(id);
    const achievements = await citang.getAchievements(id);
    const alive = await citang.getAlive(id);
    const tokenMetadataUri = await citang.tokenURI(id);

    let tokenMetadata: CiTangMetadata;

    try {
      const response = await axios.get(tokenMetadataUri);
      tokenMetadata = response.data as CiTangMetadata;
    } catch (error) {
      console.log(`failed to retrieve metadata of CiTang for ${memorialFor}`);
      tokenMetadata = { name: memorialFor, description: achievements, image: "" };
    }

    const model = new CiTangModel(
      id,
      memorialFor,
      memorialForAddress,
      burntAmount,
      achievements,
      tokenMetadata,
      owner,
      alive
    );

    return model;
  } catch (error) {
    if (error.message.includes("owner query for nonexistent token")) {
      // FIXME: catch non existent
      console.log("the token does not exist yet");
    } else {
      console.error(error);
    }
    return null;
  }
}

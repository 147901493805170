


















































































import Component from "vue-class-component";

import { BigNumber, constants, utils } from "ethers";
import { Emit, Mixins } from "vue-property-decorator";
import { i18nDefs } from "@/i18n";
import AccountHelper from "@/mixins/accounthelper";
import MingBiWithAvailableInputBox from "@/components/MingBiWithAvailableInputBox.vue";
import { EventBus } from "@/utils/EventBus";
import { ChainConfigurations } from "@/config/configs";

interface EstablishMemorialModel {
  address: string;
  initialGongDe: BigNumber;
  name: string;
  achievements: string;
  alive: boolean;
  picture: File | null;
}

@Component({ components: { MingBiWithAvailableInputBox } })
export default class EstablishMemorialCard extends Mixins(AccountHelper) {
  i = i18nDefs;
  establishModel = {
    address: constants.AddressZero,
    initialGongDe: constants.Zero,
    name: "",
    achievements: "",
    alive: true,
    picture: null,
  } as EstablishMemorialModel;

  addressUnknown = true;

  get addressValid(): boolean {
    return (
      !this.establishModel.address ||
      this.addressUnknown ||
      utils.isAddress(this.establishModel.address)
    );
  }

  establishing = false;
  establishingModel = {} as EstablishMemorialModel; // currently establishing

  get formValid(): boolean {
    return (
      this.establishModel.name.length > 0 &&
      this.establishModel.achievements.length > 0 &&
      this.addressValid
    );
  }

  async createCiTang(): Promise<void> {
    this.establishing = true;
    this.establishingModel = Object.assign(
      this.establishingModel,
      this.establishModel
    ); // duplicate one

    const contract = this.contracts?.CiTangContract;
    const signer = this.signer?.signMessage;
    if (!contract || !signer) {
      console.log("signer or contracts not ready");
      return;
    }

    const file = this.establishingModel.picture?.arrayBuffer;
    if (file) {
      // this.signer?.signMessage;
    }

    const address = this.addressUnknown
      ? constants.AddressZero
      : utils.getAddress(this.establishingModel.address);

    try {
      const tx = await contract.establish(
        this.establishingModel.alive,
        address,
        this.establishingModel.initialGongDe,
        this.establishingModel.name,
        this.establishingModel.achievements
      );

      const message = `i18n establishing citang for ${this.establishingModel.name}`;
      EventBus.sendNotificationAutoHide(message);
      const rx = await tx.wait(ChainConfigurations.CONFIRM_BLOCKS);

      const establishEvent =
        contract.interface.events["Establish(address,uint256)"];
      const id = rx.events?.find((ev) => ev.event === establishEvent.name)
        ?.args?.[1] as BigNumber | undefined;

      if (!id) {
        throw new Error("Establish event has no arg 1");
      }

      this.establishCiTangCompleted(id.toString());
      console.log(`established citang of id ${id}`);
    } catch (error) {
      console.error(error);
    }

    this.establishing = false;
  }

  @Emit()
  establishCiTangCompleted(citangId: string): void {
    console.log("emit burn mingbi completed");

    const message = `i18n established citang for ${this.establishingModel.name}, id ${citangId}!`;
    EventBus.sendNotificationAutoHide(message);

    if (this.$route.matched.some(({ name }) => name === "EstablishMemorial")) {
      this.$router.push({ name: "Memorial", params: { id: citangId } });
    }
  }

  async establishCiTangRequested(event: Event): Promise<void> {
    event.preventDefault();

    await this.createCiTang();
  }
}

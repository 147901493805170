






















import Vue from "vue";
import Component from "vue-class-component";

import { Signer } from "ethers";
import AsyncComputed from "vue-async-computed-decorator";
import MetaMaskOnboarding from "@metamask/onboarding";

import store from "@/store";
import { i18nDefs } from "@/i18n";
import {
  getWalletMethods,
  WalletGetters,
  WalletMutations,
} from "@/store/wallet";
import { connectWeb3Context, Web3Context } from "@/utils/contracts";

@Component
export default class AccountBanner extends Vue {
  i = i18nDefs;

  get walletConnected(): boolean {
    return store.getters[getWalletMethods(WalletGetters.getSignerReady)];
  }

  @AsyncComputed()
  async signerFullAddress(): Promise<string> {
    const signer = store.getters[
      getWalletMethods(WalletGetters.getSigner)
    ] as Signer | null;
    if (signer) {
      return await signer.getAddress();
    } else {
      return "...";
    }
  }

  @AsyncComputed()
  async signerAddress(): Promise<string> {
    const addr = (this.signerFullAddress as unknown) as string;
    if (addr.length == 3) {
      return addr;
    } else {
      return `${addr.substr(0, 10)}...${addr.substr(-8)}`;
    }
  }

  walletConnecting = false;
  hasMetamask = false;
  onboarding = false;

  async mounted(): Promise<void> {
    this.hasMetamask = MetaMaskOnboarding.isMetaMaskInstalled();
    if (this.hasMetamask) {
      await this.initialize();
    }
  }

  async initialize(): Promise<void> {
    // await store.dispatch(getWalletMethods(WalletActions.initializeWallet));
    const context = await connectWeb3Context(false);
    store.commit(getWalletMethods(WalletMutations.setWallet), context);

    if (context.ethereum) {
      context.ethereum.on("accountsChanged", async (accounts: []) => {
        // Handle the new accounts, or lack thereof.
        // "accounts" will always be an array, but it can be empty.
        console.log(`eth accounts has been changed`, accounts);

        let context: Web3Context;
        if (accounts.length === 0) {
          // all accounts disconnected
          context = await connectWeb3Context(false);
        } else {
          context = await connectWeb3Context(true);
        }
        store.commit(getWalletMethods(WalletMutations.setWallet), context);
      });

      context.ethereum.on("chainChanged", () => {
        // Handle the new chain.
        // Correctly handling chain changes can be complicated.
        // We recommend reloading the page unless you have good reason not to.
        window.location.reload();
      });
    }
  }

  async connectWallet(): Promise<void> {
    if (this.walletConnecting) {
      return;
    }
    this.walletConnecting = true;

    const context = await connectWeb3Context(true);
    store.commit(getWalletMethods(WalletMutations.setWallet), context);

    this.walletConnecting = false;
  }

  onboardMetamask(): void {
    const onboarding = new MetaMaskOnboarding();
    this.onboarding = true;
    onboarding.startOnboarding();
  }
}

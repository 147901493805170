











import Component from "vue-class-component";
import Vue from "vue";
import i18n, { i18nDefs } from "@/i18n";

interface LangInfo {
  language: string;
  id: string;
}

@Component
export default class LangSelector extends Vue {
  i = i18nDefs;

  get availableLanguages(): LangInfo[] {
    return i18n.availableLocales.map(
      (lang): LangInfo => {
        return {
          language: this.$t(this.i.I_LANG, lang),
          id: lang,
        } as LangInfo;
      }
    );
  }

  selectLanguage(id: string): void {
    console.log(`language selected: ${id}`);
    localStorage.setItem('locale', id);
    i18n.locale = id;
  }
}
